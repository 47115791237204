"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Rh24WebApp = void 0;
var Rh24WebApp = /** @class */ (function () {
    function Rh24WebApp(config) {
        this._container = undefined;
        this._config = config;
        this.handleMessages = this.handleMessages.bind(this);
        this.sendConfigurationMessage = this.sendConfigurationMessage.bind(this);
    }
    Rh24WebApp.prototype.render = function (rootElementId, relativePath) {
        var _a, _b;
        if (relativePath === void 0) { relativePath = '/projects'; }
        if (!this._config.partyId) {
            throw new Error('[rh24-embedded] partyId should not be null or empty');
        }
        if (!this._config.rh24BaseUrl) {
            throw new Error('[rh24-embedded] rh24BaseUrl should not be null or empty');
        }
        var element;
        if (rootElementId) {
            element = document.getElementById(rootElementId);
            document.getElementsByTagName('body')[0].style.margin = '0';
        }
        else {
            element = document.getElementsByTagName('body')[0];
            element.style.margin = '0';
        }
        if (!element) {
            throw new Error('unable to find a root element to draw Rhodium24');
        }
        var iframe = document.createElement('iframe');
        var iframeSrc = "".concat(this._config.rh24BaseUrl.replace(/'/g, ''), "/app/").concat(relativePath.startsWith('/') ? relativePath.slice(1) : relativePath);
        if (!((_a = this._config.options) === null || _a === void 0 ? void 0 : _a.enableCache)) {
            iframeSrc += "".concat(iframeSrc.indexOf('?') > -1 ? '&' : '?', "v=").concat(Math.random());
            iframeSrc = iframeSrc.replace('/?', '?');
        }
        iframe.src = iframeSrc;
        iframe.id = 'rh24-iframe';
        iframe.style.width = '100%';
        iframe.style.height = "calc(100vh - ".concat(((_b = this._config.options) === null || _b === void 0 ? void 0 : _b.marginTop) || 0, ")");
        iframe.style.border = 'none';
        iframe.setAttribute('data-testid', 'rh24-iframe');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        iframe.sandbox =
            'allow-top-navigation allow-scripts allow-same-origin allow-forms allow-modals allow-top-navigation-by-user-activation allow-downloads allow-popups allow-popups-to-escape-sandbox';
        iframe.allow = "clipboard-write ".concat(this._config.rh24BaseUrl, "; clipboard-read ").concat(this._config.rh24BaseUrl);
        element.style.overflowY = 'hidden';
        element.appendChild(iframe);
        window.onmessage = this.handleMessages;
        iframe.onload = this.sendConfigurationMessage;
        this._container = iframe;
        return iframe;
    };
    Rh24WebApp.prototype.handleMessages = function (ev) {
        var _a, _b, _c, _d, _e;
        if (ev.origin !== this._config.rh24BaseUrl) {
            return;
        }
        switch (ev.data.type) {
            case 'RH24_EMBEDDED_LOCATION_CHANGE': {
                var rh24EmbededRoute = (((_a = ev.data.payload) === null || _a === void 0 ? void 0 : _a.pathname) || '/').replace('/app', '') || '/';
                if (((_b = this._config.options) === null || _b === void 0 ? void 0 : _b.replaceHistoryStateOnLocationChange) &&
                    rh24EmbededRoute.indexOf('myorganizations') === -1) {
                    window.history.replaceState(null, rh24EmbededRoute, rh24EmbededRoute);
                }
                if ((_c = this._config.options) === null || _c === void 0 ? void 0 : _c.onLocationChange) {
                    (_d = this._config.options) === null || _d === void 0 ? void 0 : _d.onLocationChange(rh24EmbededRoute);
                }
                break;
            }
            case 'RH24_EMBEDDED_DOCUMENT_TITLE': {
                if ((_e = this._config.options) === null || _e === void 0 ? void 0 : _e.replaceDocumentTitle) {
                    document.title = ev.data.payload;
                }
                break;
            }
            case 'RH24_EMBEDDED_SETUP_RETRY': {
                this.sendConfigurationMessage();
                break;
            }
        }
    };
    Rh24WebApp.prototype.sendConfigurationMessage = function () {
        var _a, _b, _c, _d, _e, _f, _g;
        var message = {
            type: 'RH24_EMBEDDED_SETUP',
            payload: {
                partyId: (_a = this._config) === null || _a === void 0 ? void 0 : _a.partyId,
                rh24BaseUrl: (_b = this._config) === null || _b === void 0 ? void 0 : _b.rh24BaseUrl,
                parentHref: window.location.href,
                parentTitle: document.title,
                theme: __assign({}, (((_c = this._config) === null || _c === void 0 ? void 0 : _c.theme) || {})),
                themeV5: __assign({}, (((_d = this._config) === null || _d === void 0 ? void 0 : _d.themeV5) || {})),
                landingPageUrl: (_e = this._config) === null || _e === void 0 ? void 0 : _e.landingPageUrl
            }
        };
        (_g = (_f = this._container) === null || _f === void 0 ? void 0 : _f.contentWindow) === null || _g === void 0 ? void 0 : _g.postMessage(message, this._config.rh24BaseUrl);
    };
    return Rh24WebApp;
}());
exports.Rh24WebApp = Rh24WebApp;
